import { Cache } from '$app/lib/Cache'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const usePageStore = defineStore('Page', () => {
  const id = ref(0)
  const aborter = ref(new AbortController())
  const cache = new Cache()
  const stamp = ref(0)

  function newPage() {
    id.value += 1
  }

  function reload() {
    console.log('reload')
    cleanup()
    stamp.value += 1
  }

  function cleanup() {
    console.log('cleanup')
    aborter.value.abort()
    aborter.value = new AbortController()
    cache.clear()
  }

  return { id, newPage, reload, aborter, cleanup, cache, stamp }
})
