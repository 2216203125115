export const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/index.vue'),
    /* no children */
    meta: {
      "layout": "frontend",
      "auth": false
    }
  },
  {
    path: '/backend',
    /* internal name: '/backend' */
    /* no component */
    children: [
      {
        path: '',
        name: '/backend/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/backend/index.vue'),
        /* no children */
        meta: {
          "layout": "backend"
        }
      },
      {
        path: 'device_models',
        /* internal name: '/backend/device_models' */
        /* no component */
        children: [
          {
            path: '',
            name: '/backend/device_models/',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/backend/device_models/index.vue'),
            /* no children */
            meta: {
              "layout": "backend"
            }
          },
          {
            path: ':id/edit',
            name: '/backend/device_models/[id].edit',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/backend/device_models/[id].edit.vue'),
            /* no children */
            meta: {
              "layout": "backend"
            }
          }
        ],
      },
      {
        path: 'operation_systems',
        /* internal name: '/backend/operation_systems' */
        /* no component */
        children: [
          {
            path: '',
            name: '/backend/operation_systems/',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/backend/operation_systems/index.vue'),
            /* no children */
            meta: {
              "layout": "backend"
            }
          },
          {
            path: ':id/edit',
            name: '/backend/operation_systems/[id].edit',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/backend/operation_systems/[id].edit.vue'),
            /* no children */
            meta: {
              "layout": "backend"
            }
          }
        ],
      },
      {
        path: 'roms',
        /* internal name: '/backend/roms' */
        /* no component */
        children: [
          {
            path: '',
            name: '/backend/roms/',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/backend/roms/index.vue'),
            /* no children */
            meta: {
              "layout": "backend"
            }
          },
          {
            path: ':id/edit',
            name: '/backend/roms/[id].edit',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/backend/roms/[id].edit.vue'),
            /* no children */
            meta: {
              "layout": "backend"
            }
          }
        ],
      },
      {
        path: 'users',
        /* internal name: '/backend/users' */
        /* no component */
        children: [
          {
            path: '',
            name: '/backend/users/',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/backend/users/index.vue'),
            /* no children */
            meta: {
              "layout": "backend"
            }
          },
          {
            path: ':id/edit',
            name: '/backend/users/[id].edit',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/backend/users/[id].edit.vue'),
            /* no children */
            meta: {
              "layout": "backend"
            }
          },
          {
            path: 'new',
            name: '/backend/users/new',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/backend/users/new.vue'),
            /* no children */
            meta: {
              "layout": "backend"
            }
          }
        ],
      }
    ],
  },
  {
    path: '/projects',
    /* internal name: '/projects' */
    /* no component */
    children: [
      {
        path: '',
        name: '/projects/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/index.vue'),
        /* no children */
        meta: {
          "layout": "frontend"
        }
      },
      {
        path: ':id',
        name: '/projects/[id]',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[id].vue'),
        /* no children */
        meta: {
          "layout": "frontend"
        }
      },
      {
        path: ':id/edit',
        name: '/projects/[id].edit',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[id].edit.vue'),
        /* no children */
        meta: {
          "layout": "frontend"
        }
      },
      {
        path: ':project_id',
        /* internal name: '/projects/[project_id]' */
        /* no component */
        children: [
          {
            path: 'apps',
            /* internal name: '/projects/[project_id]/apps' */
            /* no component */
            children: [
              {
                path: '',
                name: '/projects/[project_id]/apps/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/apps/index.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              },
              {
                path: ':app_id',
                /* internal name: '/projects/[project_id]/apps/[app_id]' */
                /* no component */
                children: [
                  {
                    path: 'app_builds',
                    /* internal name: '/projects/[project_id]/apps/[app_id]/app_builds' */
                    /* no component */
                    children: [
                      {
                        path: ':channel',
                        name: '/projects/[project_id]/apps/[app_id]/app_builds/[channel]',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/apps/[app_id]/app_builds/[channel].vue'),
                        /* no children */
                        meta: {
                          "layout": "frontend"
                        }
                      },
                      {
                        path: ':id',
                        /* internal name: '/projects/[project_id]/apps/[app_id]/app_builds/[id]' */
                        /* no component */
                        children: [
                          {
                            path: 'publish',
                            name: '/projects/[project_id]/apps/[app_id]/app_builds/[id]/publish',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/apps/[app_id]/app_builds/[id]/publish.vue'),
                            /* no children */
                            meta: {
                              "layout": "frontend"
                            }
                          }
                        ],
                      },
                      {
                        path: ':id/edit',
                        name: '/projects/[project_id]/apps/[app_id]/app_builds/[id].edit',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/apps/[app_id]/app_builds/[id].edit.vue'),
                        /* no children */
                        meta: {
                          "layout": "frontend"
                        }
                      },
                      {
                        path: 'new',
                        name: '/projects/[project_id]/apps/[app_id]/app_builds/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/apps/[app_id]/app_builds/new.vue'),
                        /* no children */
                        meta: {
                          "layout": "frontend"
                        }
                      }
                    ],
                  },
                  {
                    path: 'releases',
                    /* internal name: '/projects/[project_id]/apps/[app_id]/releases' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/projects/[project_id]/apps/[app_id]/releases/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/apps/[app_id]/releases/index.vue'),
                        /* no children */
                        meta: {
                          "layout": "frontend"
                        }
                      },
                      {
                        path: ':id/edit',
                        name: '/projects/[project_id]/apps/[app_id]/releases/[id].edit',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/apps/[app_id]/releases/[id].edit.vue'),
                        /* no children */
                        meta: {
                          "layout": "frontend"
                        }
                      },
                      {
                        path: ':id/expire',
                        name: '/projects/[project_id]/apps/[app_id]/releases/[id].expire',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/apps/[app_id]/releases/[id].expire.vue'),
                        /* no children */
                        meta: {
                          "layout": "frontend"
                        }
                      }
                    ],
                  }
                ],
              },
              {
                path: ':id',
                name: '/projects/[project_id]/apps/[id]',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/apps/[id].vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              },
              {
                path: ':id/edit',
                name: '/projects/[project_id]/apps/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/apps/[id].edit.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              },
              {
                path: 'new',
                name: '/projects/[project_id]/apps/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/apps/new.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              }
            ],
          },
          {
            path: 'devices',
            /* internal name: '/projects/[project_id]/devices' */
            /* no component */
            children: [
              {
                path: '',
                name: '/projects/[project_id]/devices/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/devices/index.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              },
              {
                path: ':id/edit',
                name: '/projects/[project_id]/devices/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/devices/[id].edit.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              }
            ],
          },
          {
            path: 'messages',
            /* internal name: '/projects/[project_id]/messages' */
            /* no component */
            children: [
              {
                path: '',
                name: '/projects/[project_id]/messages/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/messages/index.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              }
            ],
          },
          {
            path: 'project_tokens',
            /* internal name: '/projects/[project_id]/project_tokens' */
            /* no component */
            children: [
              {
                path: '',
                name: '/projects/[project_id]/project_tokens/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/project_tokens/index.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              }
            ],
          },
          {
            path: 'pusher_apples',
            /* internal name: '/projects/[project_id]/pusher_apples' */
            /* no component */
            children: [
              {
                path: ':id/edit',
                name: '/projects/[project_id]/pusher_apples/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/pusher_apples/[id].edit.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              },
              {
                path: 'new',
                name: '/projects/[project_id]/pusher_apples/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/pusher_apples/new.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              }
            ],
          },
          {
            path: 'pusher_huaweis',
            /* internal name: '/projects/[project_id]/pusher_huaweis' */
            /* no component */
            children: [
              {
                path: ':id/edit',
                name: '/projects/[project_id]/pusher_huaweis/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/pusher_huaweis/[id].edit.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              },
              {
                path: 'new',
                name: '/projects/[project_id]/pusher_huaweis/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/pusher_huaweis/new.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              }
            ],
          },
          {
            path: 'pusher_leanclouds',
            /* internal name: '/projects/[project_id]/pusher_leanclouds' */
            /* no component */
            children: [
              {
                path: ':id/edit',
                name: '/projects/[project_id]/pusher_leanclouds/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/pusher_leanclouds/[id].edit.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              },
              {
                path: 'new',
                name: '/projects/[project_id]/pusher_leanclouds/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/pusher_leanclouds/new.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              }
            ],
          },
          {
            path: 'pusher_oppos',
            /* internal name: '/projects/[project_id]/pusher_oppos' */
            /* no component */
            children: [
              {
                path: ':id/edit',
                name: '/projects/[project_id]/pusher_oppos/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/pusher_oppos/[id].edit.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              },
              {
                path: 'new',
                name: '/projects/[project_id]/pusher_oppos/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/pusher_oppos/new.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              }
            ],
          },
          {
            path: 'pusher_settings',
            /* internal name: '/projects/[project_id]/pusher_settings' */
            /* no component */
            children: [
              {
                path: '',
                name: '/projects/[project_id]/pusher_settings/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/pusher_settings/index.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              }
            ],
          },
          {
            path: 'pusher_vivos',
            /* internal name: '/projects/[project_id]/pusher_vivos' */
            /* no component */
            children: [
              {
                path: ':id/edit',
                name: '/projects/[project_id]/pusher_vivos/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/pusher_vivos/[id].edit.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              },
              {
                path: 'new',
                name: '/projects/[project_id]/pusher_vivos/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/pusher_vivos/new.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              }
            ],
          },
          {
            path: 'pusher_xiaomi_ioses',
            /* internal name: '/projects/[project_id]/pusher_xiaomi_ioses' */
            /* no component */
            children: [
              {
                path: ':id/edit',
                name: '/projects/[project_id]/pusher_xiaomi_ioses/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/pusher_xiaomi_ioses/[id].edit.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              },
              {
                path: 'new',
                name: '/projects/[project_id]/pusher_xiaomi_ioses/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/pusher_xiaomi_ioses/new.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              }
            ],
          },
          {
            path: 'pusher_xiaomis',
            /* internal name: '/projects/[project_id]/pusher_xiaomis' */
            /* no component */
            children: [
              {
                path: ':id/edit',
                name: '/projects/[project_id]/pusher_xiaomis/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/pusher_xiaomis/[id].edit.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              },
              {
                path: 'new',
                name: '/projects/[project_id]/pusher_xiaomis/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/pusher_xiaomis/new.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              }
            ],
          },
          {
            path: 'reports',
            /* internal name: '/projects/[project_id]/reports' */
            /* no component */
            children: [
              {
                path: '',
                name: '/projects/[project_id]/reports/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/[project_id]/reports/index.vue'),
                /* no children */
                meta: {
                  "layout": "frontend"
                }
              }
            ],
          }
        ],
      },
      {
        path: 'new',
        name: '/projects/new',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/projects/new.vue'),
        /* no children */
        meta: {
          "layout": "frontend"
        }
      }
    ],
  },
  {
    path: '/sign_in',
    name: 'sign_in',
    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/small-corn/app/javascript/app/pages/sign_in.vue'),
    /* no children */
    meta: {
      "layout": "blank",
      "auth": false
    }
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

