<template>
  <RouterView />
</template>

<script setup lang="ts">
import { RouterView, useRoute, useRouter } from 'vue-router'
import { UnauthorizedError } from './requests/UnauthorizedError'
import { onErrorCaptured } from 'vue'

const router = useRouter()
const route = useRoute()

onErrorCaptured((error) => {
  if (error instanceof UnauthorizedError) {
    router.push({ name: 'sign_in', query: { ok_url: route.fullPath } })
  }
})
</script>
