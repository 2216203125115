import { setupLayouts } from 'virtual:generated-layouts'
import type { App } from "vue"
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import { routes } from 'vue-router/auto-routes'


// function traverseRoutes(routes: RouteRecordRaw[], callback: (route: RouteRecordRaw) => void) {
//   for (const route of routes) {
//     callback(route)

//     if (route.children && route.children.length > 0) {
//       traverseRoutes(route.children, callback)
//     }
//   }
// }

// traverseRoutes(routes, (route) => {
//   if (route.meta?.layout) {
//     route.meta.layout = `app/layouts/${route.meta.layout}`
//   }
// })

const routesWithLayouts = setupLayouts(routes)

console.log(routesWithLayouts)

const router = createRouter({
  history: createWebHistory(""),
  routes: routesWithLayouts,
})

export default function(app: App) {
  app.use(router)
}
