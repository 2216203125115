import { usePageStore } from "$app/stores/page"
import type { App } from "vue"

export default function(app: App) {
  const router = app.config.globalProperties.$router
  const pinia = app.config.globalProperties.$pinia

  router.beforeEach((to, from) => {
    const page = usePageStore(pinia)
    page.cleanup()
  })

  router.afterEach((to, from) => {
    const page = usePageStore(pinia)
    page.newPage()
  })
}
